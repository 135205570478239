import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Card, Table } from 'react-bootstrap';
import { LimbusCharacter } from '../../../modules/lc/common/components/lc-character';
import { LimbusEgo } from '../../../modules/lc/common/components/lc-ego';

const LimbusUptieGuide: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="lc">
      <ul className="breadcrumb">
        <li>
          <Link to="/limbus-company/">Limbus Company</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/limbus-company/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Uptie 4 priority guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/limbuscompany/categories/category_uptie.png"
            alt="Uptie 4 priority guide"
          />
        </div>
        <div className="page-details">
          <h1>Uptie 4 priority guide</h1>
          <h2>Which ID and EGO should you prioritize for Uptie 4.</h2>
          <p>
            Last updated: <strong>13/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          With the update on 20th of July, Uptie 4 has been released. Uptie 4
          boosts Identities and EGO’s performance more or less significantly.
          However, Uptie 4 is expensive in terms of thread and shards it
          requires. Below is the priority of the Identities and EGO that receive
          the biggest boost from Uptie 4. Priority goes from top, the most
          important, to bottom, least important.
        </p>
        <SectionHeader title="ID" />
        <Table striped bordered responsive className="id-tier">
          <thead>
            <tr>
              <th>Ranking</th>
              <th>ID</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>Totally worth</strong>
              </td>
              <td>
                <div className="employees-container-card-mode-limbus">
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="r-corp-4th-pack-reindeer-ishmael"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="w-corp-l3-cleanup-agent-don-quixote"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="w-corp-l3-cleanup-agent-ryoshu"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="dieci-south-section-4-rodion"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="t-corp-class-3-collection-staff-don-quixote"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="the-ring-pointillist-student-yi-sang"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="multicrack-office-rep-faust"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Worth</strong>
              </td>
              <td>
                <div className="employees-container-card-mode-limbus">
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="w-corp-l3-cleanup-agent-yi-sang"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="lobotomy-e-g-o-regret-faust"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="k-corp-class-3-excision-staff-hong-lu"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="blade-lineage-salsu-yi-sang"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="dieci-assoc-south-section-4-hong-lu"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="wuthering-heights-butler-faust"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="the-ring-pointillist-student-outis"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="w-corp-l3-cleanup-captain-outis"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="liu-assoc-south-section-4-director-rodion"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="t-corp-class-2-collection-staff-rodion"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Good choice</strong>
              </td>
              <td>
                <div className="employees-container-card-mode-limbus">
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="w-corp-l2-cleanup-agent-meursault"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="seven-south-section-4-heathcliff"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="w-corp-l2-cleanup-agent-faust"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="molar-office-fixer-outis"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="seven-association-south-section-6-ryoshu"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="edgar-family-heir-gregor"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="cinq-south-section-4-outis"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="blade-lineage-salsu-sinclair"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="wild-hunt-heathcliff"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Decent</strong>
              </td>
              <td>
                <div className="employees-container-card-mode-limbus">
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="seven-south-section-4-faust"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="hook-office-fixer-hong-lu"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="r-corp-4th-pack-rabbit-heathcliff"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusCharacter
                      slug="liu-south-section-4-ishmael"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="EGO" />
        <Table striped bordered responsive className="id-tier">
          <thead>
            <tr>
              <th>Ranking</th>
              <th>ID</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>Totally worth</strong>
              </td>
              <td>
                <div className="employees-container-card-mode">
                  <Card className="avatar-card">
                    <LimbusEgo
                      slug="fluid-sac-faust"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusEgo
                      slug="rime-shank-rodion"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusEgo
                      slug="4th-match-flame-ryoshu"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusEgo
                      slug="solemn-lament-gregor"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Worth</strong>
              </td>
              <td>
                <div className="employees-container-card-mode">
                  <Card className="avatar-card">
                    <LimbusEgo
                      slug="sunshower-yi-sang"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusEgo
                      slug="capote-meursault"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Good choice</strong>
              </td>
              <td>
                <div className="employees-container-card-mode">
                  <Card className="avatar-card">
                    <LimbusEgo
                      slug="fluid-sac-don-quixote"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusEgo
                      slug="dimension-shredder-yi-sang"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusEgo
                      slug="ya-sunyata-tad-rupam-heathcliff"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusEgo
                      slug="ardor-blossom-star-ishmael"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusEgo
                      slug="regret-meursault"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusEgo
                      slug="aedd-gregor"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusEgo
                      slug="telepole-don-quixote"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusEgo
                      slug="dimension-shredder-hong-lu"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusEgo
                      slug="everlasting-faust"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusEgo
                      slug="dimension-shredder-outis"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Decent</strong>
              </td>
              <td>
                <div className="employees-container-card-mode">
                  <Card className="avatar-card">
                    <LimbusEgo
                      slug="red-eyes-open-ryoshu"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusEgo
                      slug="bodysack-heathcliff"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusEgo
                      slug="impending-day-sinclair"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusEgo
                      slug="ebony-stem-outis"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                  <Card className="avatar-card">
                    <LimbusEgo
                      slug="holiday-outis"
                      mode="card"
                      enablePopover
                      showLabel
                    />
                  </Card>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default LimbusUptieGuide;

export const Head: React.FC = () => (
  <Seo
    title="Uptie 4 priority guide | Limbus Company | Prydwen Institute"
    description="Which ID and EGO should you prioritize for Uptie 4."
    game="limbus"
  />
);
